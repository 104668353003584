import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  TokenSalePurchase,
  GetTokenSalePurchasesParams,
  GetTokenSalePurchasesCsvParams
} from '@/api/schema';

export async function getTokenSalePurchases(
  params: GetTokenSalePurchasesParams
): Promise<{ content: TokenSalePurchase[]; totalElements: number }> {
  return http
    .get(buildUrl(`/api/v2/{operatorId}/token-sale/purchases`), {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse) => data);
}

export async function getTokenSalePurchasesCsv(
  params: GetTokenSalePurchasesCsvParams
): Promise<string> {
  return http
    .get(buildUrl(`/api/v2/{operatorId}/token-sale/purchases/csv`), {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse) => data);
}
