



























































import { mapState, mapGetters } from 'vuex';
import capitalize from 'lodash/capitalize';
import formatDate from '@/helpers/formatDate';
import {
  getTokenSalePurchases,
  getTokenSalePurchasesCsv
} from '@/api/TokenSale';
import type {
  TokenSalePurchase,
  GetTokenSalePurchasesParams,
  GetTokenSalePurchasesCsvParams
} from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { doDownload } from '@/helpers/download';

type Data = {
  tableOptions: {
    page: number;
    itemsPerPage: number;
  };
  totalTokenSalePurchases: number;
  tableTokenSalePurchases: TokenSalePurchase[];
  loading: boolean;
  loadingCSV: boolean;
  operatorUserId: string;
  itemsPerPage: number[];
};

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'TokensaleView',
  data: (): Data => ({
    tableOptions: { ...defaultOptions },
    totalTokenSalePurchases: 0,
    tableTokenSalePurchases: [],
    loading: true,
    loadingCSV: false,
    operatorUserId: '',
    itemsPerPage: [10, 20, 50]
  }),
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('FeatureFlags', {
      features: 'features',
      isFeaturesLoading: 'loading'
    }),
    ...mapGetters('Onboarding', ['operatorId']),
    operatorUserIdInput: {
      get(): string {
        return this.operatorUserId;
      },
      set(newVal: string): void {
        if (!newVal) {
          this.type = null;
        }

        this.operatorUserId = newVal;
      }
    },
    tableHeaders(): { [key: string]: string }[] {
      return [
        {
          text: 'Purchase Date',
          value: 'purchaseDate'
        },
        {
          text: 'Operator User Id',
          value: 'operatorUserId'
        },
        {
          text: 'Amount',
          value: 'paymentAmount'
        },
        {
          text: 'Amount Tokens',
          value: 'tokenAmount'
        },
        {
          text: 'Bonus Tokens',
          value: 'bonusTokenAmount'
        },
        {
          text: 'Token Sale Type',
          value: 'tokenSaleType'
        }
      ];
    },
    featureFlags(): unknown {
      return Object.fromEntries(
        (this.features || []).map((feature) => {
          return [feature.name, feature.enabled !== false];
        })
      );
    },
    isPageAvailable(): boolean {
      if (this.isSuperAdmin || this.isFeaturesLoading) {
        return true;
      }

      return (
        this.$role.can.read('token_manage') &&
        (this.featureFlags?.tokensale_private ||
          this.featureFlags?.tokensale_public)
      );
    }
  },
  watch: {
    isPageAvailable: {
      handler(): void {
        if (this.isFeaturesLoading) {
          return;
        }

        if (!this.isPageAvailable) {
          this.$router.push('/');
        }
      },
      immediate: true
    },
    tableOptions: {
      async handler(): Promise<void> {
        if (this.operatorId) {
          await this.getTokenSalePurchases();
        }
      },
      deep: true
    },
    operatorId: {
      handler(operatorId: number | null): void {
        if (operatorId) {
          this.tableOptions = { ...defaultOptions };
        }
      },
      immediate: true
    }
  },
  methods: {
    formatDate,
    formatTokenSaleType(type: string): string {
      return capitalize(type.replace(/_/g, ' '));
    },
    submitForm(): void {
      this.resetPagination();
      this.$nextTick((): void => {
        this.getTokenSalePurchases();
      });
    },
    async getTokenSalePurchases(): Promise<void> {
      try {
        this.loading = true;
        let params = {
          page: this.tableOptions.page - 1,
          size: this.tableOptions.itemsPerPage,
          ...(this.operatorUserId
            ? { operatorUserId: this.operatorUserId }
            : {})
        } as GetTokenSalePurchasesParams;
        const { content, totalElements } = await getTokenSalePurchases(
          params
        );
        this.tableTokenSalePurchases = content;
        this.totalTokenSalePurchases = totalElements;
      } catch (error) {
        errorToastMessage(error);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async changePage(page: number): Promise<void> {
      await this.getTokenSalePurchases();
      this.page = page;
    },
    async getCsvReport(): Promise<void> {
      this.loadingCSV = true;

      try {
        let params = {
          ...(this.operatorUserId
            ? { operatorUserId: this.operatorUserId }
            : {})
        } as GetTokenSalePurchasesCsvParams;
        const data = await getTokenSalePurchasesCsv(params);
        const url = window.URL.createObjectURL(new Blob([data]));
        doDownload(url, `Token Sale Purchases report.csv`);
      } catch (error) {
        errorToastMessage(error);
        console.error(error);
      } finally {
        this.loadingCSV = false;
      }
    },
    resetPagination(): void {
      this.tableOptions.page = 1;
    }
  }
};
